import React, { Component } from "react";
import PerformaceAnalyticsTable from "./performanceAnalyticsTable";
import {
  DistrictBarChart,
  ProjectPieChart,
  SiteBudgetBarChart,
  DistrictRemitPaymentBarChart,
  ContractorSentPaymentBarChart,
  ExpenditurePastYearsBarChart,
  ExpenditurePastMonthBarChart
} from "./charts/graphComponents";

export default class SectionFeatures extends Component {
  render() {
    return (
      <div
        className={this.backgroundColorSelector()}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        id={this.props.id}
      >
        <div className="my-class col-sm-12">
          <div className={this.textColorSelectorTitle()}>
            <h1 className="mb-5">{this.props.title}</h1>
            <h3>{this.props.subTitle0}</h3>
          </div>
        </div>
        <div className="col-sm-12">
          <p className={this.textColorSelectorParagraph()}>
            {this.props.paragraph0}
          </p>
        </div>

        <div className="col-sm-12 p-4">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-4 align-self-center">
                  <h3 className="text-center">District Level Budget</h3>
                </div>

                <div className="col-sm-12 col-md-8 ">
                  <DistrictBarChart />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 p-4">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-4 align-self-center">
                  <h3 className="text-center">Site Level Budget</h3>
                </div>

                <div className="col-sm-12 col-md-8 ">
                  <SiteBudgetBarChart />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12 p-4">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-4 align-self-center">
                  <h3 className="text-center">Project Level Budget</h3>
                  <div className="text-center pt-3">
                    <p class="">
                      Project: Hate Magnolia Middle School Modernization
                    </p>
                    <p class="">Resource: Measure X </p>
                    <p class="">Spent: $185,898.87 </p>
                    <p class="">Remaining: $1,424,958.62 </p>
                    <p class="">Project Budget: $1,610,850.31 </p>
                    <p class="">Percentage Spent: 11.54% </p>
                  </div>
                </div>

                <div className="col-sm-12 col-md-8 ">
                  <ProjectPieChart />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-sm-12">
          <div className={this.textColorSelectorTitle()}>
            <h3 className="mt-5">{this.props.subTitle1}</h3>
          </div>
          <p className={this.textColorSelectorParagraph()}>
            {this.props.paragraph1}
            <ul>
              <li className="pt-3">
                Resources available, and spent per resources: Percentages and
                Remaining amounts
              </li>
              <li>
                Analysis of expenditures over time. (How much is being spent
                each month | quarter | year
              </li>
              <li>Soft and hard costs analysis</li>
              <li>
                Performace Analytics : Number of Vendors, Invoices, Projects,
                etc...
              </li>
              <li>
                Additional Analytic Charts are under development. What would you
                like to see?
              </li>
            </ul>
          </p>
        </div>

        <div className="col-sm-12 p-4">
          <div className="card shadow border-0">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <DistrictRemitPaymentBarChart />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <ExpenditurePastYearsBarChart />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <ExpenditurePastMonthBarChart />
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <ContractorSentPaymentBarChart />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <PerformaceAnalyticsTable />
                </div>
                <div className="col-sm-12 col-md-12 col-lg-4 p-4">
                  <ProjectPieChart />
                  <div className="text-center pt-3">
                    <p className="m-1">
                      Project: Hate Magnolia Middle School Modernization
                    </p>
                    <p className="m-1">Resource: Measure X </p>
                    <p className="m-1">Spent: $185,898.87 </p>
                    <p className="m-1">Remaining: $1,424,958.62 </p>
                    <p className="m-1">Project Budget: $1,610,850.31 </p>
                    <p className="m-1">Percentage Spent: 11.54% </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  textColorSelectorTitle() {
    let classAttributes = "";
    classAttributes += this.props.backgroundcolor === "blue" ? "h1-white" : "";
    return classAttributes;
  }

  textColorSelectorParagraph() {
    let classAttributes = "";
    classAttributes += this.props.backgroundcolor === "blue" ? " p-white" : "";
    return classAttributes;
  }

  backgroundColorSelector() {
    let classAttributes = "no-lr-padding-sm row p-5";
    classAttributes +=
      this.props.backgroundcolor === "blue" ? " bkg-blue" : " bkg-white";
    return classAttributes;
  }
}
