import React, { Component } from "react";

class SectionJumbotron extends Component {
  render() {
    return (
      <div
        className={this.backgroundColorSelector()}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        id={this.props.id}
      >
        <div className="col-sm-12" align="center">
          <div>
            {" "}
            <span className="logo-blue-h0">108</span>
            <span className="logo-h0">Beans</span>
          </div>

          <div className="h1-bold m-4">See the big picture?</div>

          <div className="h3 m-4 ">
            108Beans.com provides simple status reports for your facilities
            programs.
          </div>

          <a className="btn btn-primary pr-5 pl-5 mt-4" style={{color: "white"}} href="https://www.108beans.io/">
            <b>Try it free!</b>
          </a>
        </div>
      </div>
    );
  }

  backgroundColorSelector() {
    let classAttributes = "row pt-5 pb-5";
    classAttributes +=
      this.props.backgroundcolor === "blue" ? " bkg-blue" : " bkg-white";
    return classAttributes;
  }
}

export default SectionJumbotron;
