import "./App.scss";
import React from "react";
import logo from "./logo.svg";
import NavigationBar from "./components/navigationBar";
import MainContent from "./components/mainContent";

function App() {
  return (
    <div>
      <NavigationBar />
      <div className="container-fluid" style={{'paddingTop': 70}}>
        < MainContent />
      </div>
    </div>
  );
}

export default App;
