import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";



export default class Footer extends Component {

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <footer className="page-footer font-small blue pt-5">
        <div className="footer text-center py-3" >
          <span onClick={this.scrollToTop}>
            {" "}
            <span className="logo-blue-h2">108</span>
            <span className="logo-h2">Beans.com</span>
          </span>
        </div>

        <div className="footer-copyright text-right py-3">
          © 2020 Copyright 108 Solutions. LLC.
          <p>
            6 x 6 x 3 = 108 : <a href="#home" onClick={this.scrollToTop}> BadASS </a> produciton
          </p>
        </div>
      </footer>
    );
  }
}
