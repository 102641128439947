import React, { Component } from "react";
import Chart from "chart.js";

Chart.defaults.global.defaultFontColor = '#000000';
Chart.defaults.global.defaultFontFamily = "'Rubik', 'sans-serif'";
Chart.defaults.global.defaultResponsive = true;

Chart.defaults.global.elements.rectangle.borderWidth = 2;
Chart.defaults.global.elements.rectangle.borderColor = "#006BE6";
Chart.defaults.global.elements.rectangle.backgroundColor = ["#7FB4F2","#c0daf4"];
Chart.defaults.global.elements.rectangle.maxBarThickness = 60;

Chart.defaults.global.tooltips.enabled = false;
Chart.defaults.global.tooltips.callbacks.labels = {
    function(tooltipItem, data) {
        var value = data.datasets[0].data[tooltipItem.index];
        value = Math.round(value);
        value = value.toString();
        value = value.split(/(?=(?:...)*$)/);
        value = value.join(",");
        return value;
      }
};

Chart.defaults.global.legend.position = "bottom";
Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.reverse = false;



export const DistrictRemitPaymentBarChart = class districtRemitPaymentBarChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    var data = {
      labels: ["Less than 30", "30 to 60 ", "60 to 90", "Greater than 90"],
      datasets: [
        {
          label: "Invoices",
          data: [1477, 900, 80, 30],
          backgroundColor: ["#7FB4F2", "#7FB4F2", "#7FB4F2"],
        }
      ]
    };


    var options = {
        title: {
            display: true,
            text: "Remit Payment",
        },
      legend: {
        labels: {
        }
      },
      tooltips: {  
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {   
            },
            scaleLabel: {
                display: true,                
                labelString: "Invoices",
            }          
          }
        ],
        yAxes: [
          {
            stacked: true,    
            scaleLabel: {
                display: true,                
                labelString: "Days",
            }          
          }
        ]
      }
    };

    new Chart(ctx, {
      type: "horizontalBar",
      data: data,
      options: options
    });
  }
  render() {
    return (
      <div className="chart-container">
        <canvas id="myChart" width="600" height="400" ref={this.chartRef} />
      </div>
    );
  }
}

export const ContractorSentPaymentBarChart = class contractorSentPaymentBarChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    var data = {
      labels: ["Less than 30", "30 to 60 ", "60 to 90", "Greater than 90"],
      datasets: [
        {
          label: "Invoices",
          data: [1000, 900, 800, 30],
          backgroundColor: ["#7FB4F2", "#7FB4F2", "#7FB4F2"],
        }
      ]
    };


    var options = {
        title: {
            display: true,
            text: "Contractors Sent Payment",
        },
      legend: {
        labels: {
        }
      },
      tooltips: {  
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {   
            },
            scaleLabel: {
                display: true,                
                labelString: "Invoices",
            }          
          }
        ],
        yAxes: [
          {
            stacked: true,    
            scaleLabel: {
                display: true,                
                labelString: "Days",
            }          
          }
        ]
      }
    };

    new Chart(ctx, {
      type: "horizontalBar",
      data: data,
      options: options
    });
  }
  render() {
    return (
      <div className="chart-container">
        <canvas id="myChart" width="600" height="400" ref={this.chartRef} />
      </div>
    );
  }
}

export const ExpenditurePastYearsBarChart = class expenditurePastYearsBarChart extends Component {
    chartRef = React.createRef();
  
    componentDidMount() {
      const ctx = this.chartRef.current.getContext("2d");
  
      var data = {
        labels: ["2019-01-01", "2019-02-01", "2019-03-01", "2019-04-01", "2019-05-01", "2019-06-01", "2019-07-01", "2019-08-01","2019-09-01", "2019-10-01", "2019-11-01", "2019-12-01"],
        datasets: [
          {
            label: "Invoices",
            data: [5000000, 4500000, 6000000, 5008000, 1477555, 5569452, 500000, 4569846, 1477000, 900000, 80000, 1477555],
            backgroundColor: ["#7FB4F2", "#7FB4F2", "#7FB4F2"],
          }
        ]
      };
  
  
      var options = {
          title: {
              display: true,
              text: "Past Year Expenditures (2019)",
          },
        legend: {
          labels: {
          }
        },
        tooltips: {  
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              ticks: {   
              },
              scaleLabel: {
                  display: true,                
                  labelString: "Months",
              }          
            }
          ],
          yAxes: [
            {
              stacked: false,    
              scaleLabel: {
                  display: true,                
                  labelString: "Dollars",
              }          
            }
          ]
        }
      };
  
      new Chart(ctx, {
        type: "bar",
        data: data,
        options: options
      });
    }
    render() {
      return (
        <div className="chart-container">
          <canvas id="myChart" width="600" height="400" ref={this.chartRef} />
        </div>
      );
    }
}

export const ExpenditurePastMonthBarChart = class expenditurePastMonthBarChart extends Component {
    chartRef = React.createRef();
  
    componentDidMount() {
      const ctx = this.chartRef.current.getContext("2d");
  
      var data = {
        labels: ["1", "2", "3", "4", "5","6","7","8", "9", "10", "11", "12", "13","14","15","16", "17", "18", "19", "20", "21","22","23","24", "25", "26", "27", "28", "29","30","31"],
        datasets: [
          {
            label: "Invoices",
            data: [6405, 15396, 67672, 21116, 35802, 55360, 3439, 9728, 77844, 48246, 52148, 67881, 6993, 66199, 10315, 46789, 37587, 4272, 42795, 76901, 44503, 15727, 23003, 61605, 26487, 19739, 75947, 15721, 2775, 60313, 39489],
            backgroundColor: ["#7FB4F2", "#7FB4F2", "#7FB4F2"],
          }
        ]
      };
  
  
      var options = {
          title: {
              display: true,
              text: "Past Month Expenditures (January)",
          },
        legend: {
          labels: {
          }
        },
        tooltips: {  
        },
        scales: {
          xAxes: [
            {
              stacked: false,
              ticks: {   
              },
              scaleLabel: {
                  display: true,                
                  labelString: "Days",
              }          
            }
          ],
          yAxes: [
            {
              stacked: false,    
              scaleLabel: {
                  display: true,                
                  labelString: "Dollars",
              }          
            }
          ]
        }
      };
  
      new Chart(ctx, {
        type: "bar",
        data: data,
        options: options
      });
    }
    render() {
      return (
        <div className="chart-container">
          <canvas id="myChart" width="600" height="400" ref={this.chartRef} />
        </div>
      );
    }
}

export const ProjectPieChart = class ProjectPieChart extends Component{
    chartRef = React.createRef(); 

    componentDidMount(){
        const ctx = this.chartRef.current.getContext("2d");

        var data = {
            labels: ["Spent", "Remaining"],
            datasets: [
                {
                    data:[1424958.62,185898.87],
                    backgroundColor: ["#7FB4F2", "#c0daf4"],
                    borderColor: ["#006BE6","#006BE6"],                    
                },
            ]
          };
        
        var options = {   
            legend: {  
                display: true,
            },            
            tooltips: {                                
                callbacks: {
                    label: function(tooltipItem, data) {
                        var value = data.datasets[0].data[tooltipItem.index];
                        value = value.toString();
                        value = value.split(/(?=(?:...)*$)/);
                        value = value.join(',');
                        value = '$' + value;
                        return value;
                    }
                }
            }                                  
        };
        
        new Chart(ctx, {            
            type: 'pie',
            data: data,
            options: options,
        });

    }
    render() {
        return (
            <div className="chart-container">
                <canvas id="projectPieChart" width="600" height="400" ref={this.chartRef}/>
            </div>
        )
    } 
}

export const SiteBudgetBarChart = class SiteBudgetBarChart extends Component{
    chartRef = React.createRef(); 

    componentDidMount(){
        const ctx = this.chartRef.current.getContext("2d");

        var data = {
            labels:["Hate Magnolia Middle School", "Spirit Hickory High School", "Black Holly High School", "Ordinary Sycamore Elementary School", "Poisonous Cassava Middle School", "Stink Larch Elementary School", "Chilling Milkbark Middle School", "Harmless Alder Elementary School", "Blue Cassava Elementary School", "Skulking Hornbeam Middle School", "Shocking Cypress Middle School", "Iaflander Middle School", "Thacca Middle School", "Udlaegeam High School", "Kricine Middle School"],
            datasets:[
            {
                backgroundColor:"#7FB4F2",
                borderColor:"#006BE6",
                label:"Spent",
                data:[27366898.15,19945721.72,6180534.32,4421848.61,4361339.54,735398.52,5596753.52,5531876.03,4669806.82,3710825.79,2790463.78,1694592.41,356776.6,5020.0]
            },
            {
                backgroundColor:"#c0daf4",
                borderColor:"#006BE6",                
                label:"Remaining",
                data:[1617755.85,15377330.28,26414630.68,13510575.39,13048366.46,139264601.48,8592107.48,6852315.97,3909292.18,1710883.182,10648824.22,7532258.59,67643223.4,17404686.0]
            }]
        };
        
        var options = {  
                                            
            scales: {                
                xAxes: [{
                    stacked: true,
                    ticks: {                        
                        callback: function(value, index, values){
                            value = value.toString();
                            value = value.split(/(?=(?:...)*$)/);
                            value = value.join(',');
                            return '$' + value;
                        }
                    }
                }],
                yAxes: [{
                    stacked: true,
                            
                }]
           }
        };
        
        new Chart(ctx, {            
            type: 'horizontalBar',
            data: data,
            options: options,
        });

    }
    render() {
        return (
            <div className="chart-container">
                <canvas id="myChart" width="600" height="400" ref={this.chartRef}/>
            </div>
        )
    } 
}

export const DistrictBarChart = class DistrictBarChart extends Component{
    chartRef = React.createRef();

  componentDidMount() {
    const ctx = this.chartRef.current.getContext("2d");

    var data = {
      labels: [" Measure X | Y", "Measure Z - HS", "Measure U - ES"],
      datasets: [
        {
          label: "Spent",
          data: [90768377.11, 66307915.91, 24460461.2],
          backgroundColor: ["#7FB4F2", "#7FB4F2", "#7FB4F2"],
        },
        {
          label: "Remaining",
          backgroundColor: ["#c0daf4", "#c0daf4", "#c0daf4"],
          data: [117231622.89, 73692084.09, 43539538.8]
        }
      ]
    };


    var options = {
      legend: {
        labels: {
        }
      },
      tooltips: {  
      },
      scales: {
        xAxes: [
          {
            stacked: true,
            ticks: {
              callback: function(value, index, values) {
                value = value.toString();
                value = value.split(/(?=(?:...)*$)/);
                value = value.join(",");
                return "$" + value;
              }
            }
          }
        ],
        yAxes: [
          {
            stacked: true,           
          }
        ]
      }
    };
        
        new Chart(ctx, {            
            type: 'horizontalBar',
            data: data,
            options: options,
        });

    }
    render() {
        return (
            <div className="chart-container">
                <canvas id="myChart" width="600" height="400" ref={this.chartRef}/>
            </div>
        )
    } 
}
