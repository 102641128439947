import React, { Component } from "react";

export default class PerformaceAnalyticsTable extends Component {
  render() {
    return (
      <div className="card border-primary">
        <div class="card-header bg-primary white-text">
          Performace Analytics
        </div>
        <div className="card-body">
          <div className="table-responsive-sm">
            <table className="table table-hover border-top-0">
              <thead>
                <tr>
                  <th scope="col">Type</th>
                  <th scope="col">Details</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Invoices</td>
                  <td>14,508</td>
                </tr>
                <tr>
                  <td>Vendors</td>
                  <td>3258</td>
                </tr>
                <tr>
                  <td>Projects</td>
                  <td>125</td>
                </tr>
                <tr>
                  <td>Sites</td>
                  <td>28</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
