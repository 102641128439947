import React, { Component } from "react";
import SectionJumbotron from "./sectionJumbotron";
import SectionFeatures from "./sectionFeatures";
import SectionPartnering from "./sectionPartnering"
import SectionAbout from "./sectionAbout"
import Footer from "./footer";

export default class MainContent extends Component {
  render() {
    return (
      <div>
        <SectionJumbotron id="home" />

        <SectionAbout
          title = "About"
          paragraph0 = "108 Beans (Bond Expenditure Analytics) is online Software that can 
                be accessed by multiple users to track and analyse facility program expenditures. 
                This software can be used by your in-house staff or by outside consultants."
          paragraph1 = "If you have experienced difficulty in getting simple status reports 
                of your facilities program, this software may be just what you need. Our software 
                provides reports for School Boards, Superintendents, Business officials, 
                Facilities Directors, and Oversite Committees."
          backgroundcolor = "blue"
          id = "about"
        />

        <SectionFeatures
        title = "Features"
        subTitle0 = "Budgets"
        subTitle1 = "Charts and Analytics"
        paragraph0 = "Track how funds are being spent by district level budget, site budget, and project budget."
        paragraph1 = "Web based dashboard provides analytic charts at a glance."
        id = "features"
        />

        <SectionPartnering
          title="Partnering Opportunities"
          paragraph0="We are currently seeking School District Partners. "
          paragraph1="Please call us at 650-340-0431 or email us at info@108Beans.com for more information 
                and a demonstration. Pricing is not yet determined and is currently negotiable."
          backgroundcolor="blue"
          myShadow="shadow"
          id="partnering"
        />

        <Footer />
      </div>
    );
  }
}
