import React, { Component } from "react";

class SectionAbout extends Component {
  render() {
    return (
      <div
        className={this.backgroundColorSelector() + " " + this.props.myShadow}
        style={{ paddingLeft: 0, paddingRight: 0 }}
        id={this.props.id}
      >
        <div className="col-sm-12">
          <div className={this.textColorSelectorTitle()}>
            <h1 className="mb-5" >{this.props.title}</h1>
          </div>
        </div>
        <div className="col-sm-12">
          <p className={this.textColorSelectorParagraph()}>
            {this.props.paragraph0}
          </p>
          <p className={this.textColorSelectorParagraph()}>
            {this.props.paragraph1}
          </p>
        </div>
      </div>
    );
  }

  textColorSelectorTitle() {
    let classAttributes = "";
    classAttributes += this.props.backgroundcolor === "blue" ? "h1-white" : "";
    return classAttributes;
  }

  textColorSelectorParagraph() {
    let classAttributes = "";
    classAttributes += this.props.backgroundcolor === "blue" ? " p-white" : "";
    return classAttributes;
  }

  backgroundColorSelector() {
    let classAttributes = "no-lr-padding-sm row p-5";
    classAttributes +=
      this.props.backgroundcolor === "blue" ? " bkg-blue" : " bkg-white";
    return classAttributes;
  }
}

export default SectionAbout;
