import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import React, { Component } from "react";
import { Link, animateScroll as scroll } from "react-scroll";


class NavBar extends Component {
  

  state = {
    expanded: {},
  };

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  setNavExpanded = (expanded) => {
    this.setState({ navExpanded: expanded });
  };

  closeNav = () => {
    this.setState({ navExpanded: false });
  };

  render() {
    return (
      <Navbar
        className="navbar-light fixed-top shadow p-3 mb-5 bg-white"
        expand="lg"
        onToggle={this.setNavExpanded}
        expanded={this.state.navExpanded}>
      
        <span onClick={this.scrollToTop}>
          {" "}
          <span className="logo-blue-h2">108</span>
          <span className="logo-h2">Beans</span>
        </span>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="links ml-4 mr-auto">
            <Nav.Item>
              <Link
                className="nav-link"
                activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.closeNav} 
              >
                About
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className="nav-link"
                activeClass="active"
                to="features"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.closeNav} 
              >
                Features
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className="nav-link"
                activeClass="active"
                to="partnering"
                spy={true}
                smooth={true}
                offset={-70}
                duration={500}
                onClick={this.closeNav}   
              >
                Partnering Opportunities
              </Link>
            </Nav.Item>
          </Nav>

          <Nav className="justify-content-end">
            <Nav.Item>
              <Button
                variant="outline-primary"
                className=" ml-3 pr-4 pl-4 mb-2"
                href="https://www.108beans.io/my/users/sign_in"
              >
                Log in
              </Button>{" "}
            </Nav.Item>
            <Nav.Item>
              <Button
                variant="primary"
                className="ml-3 pr-4 pl-4"
                href="https://www.108beans.io/my/users/sign_up"
                style={{ color: "white" }}
              >
                Sign up
              </Button>{" "}
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default NavBar;
